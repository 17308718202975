@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}
.form-label {
  // color: #323e45;
  // font-weight: 600;
  // font-size: .75rem;
}

.h-100vh {
  height: 100vh;
}
.h-full {
  height: 100%;
}
.logo-auth {
  width: 200px;
}

.bg-success-alternative {
  background-color: #0b9238;
}

.page-header {
  border-bottom: 0.0625rem solid rgba(#0a595f, 0.1);
}

.relative {
  position: relative;
}
.view {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  background: white;
  overflow-y: scroll;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.4s ease;

  &.show {
    transform: translateX(0%);
  }
}

.datatable-custom, .datatable-vendor{
  overflow: visible !important;
}
.react-select-creatable {
  &.is-invalid {
    .react-select__control {
      border-color: #ed4c78;
      &:hover {
        border-color: #ed4c78;
      }
    }
  }
  .react-select__control {
    display: flex;
    width: 100%;
    padding: 0.4rem 0.7rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid rgba(231, 234, 243, 0.7);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.3125rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      border-color: rgba(231, 234, 243, 0.7);
    }
    &.react-select__control--is-focused {
      color: #1e2022;
      background-color: #fff;
      border-color: rgba(140, 152, 164, 0.25);
      outline: 0;
      box-shadow: 0 0 1rem 0 rgb(140 152 164 / 25%);
    }
    .react-select__value-container {
      padding: 0;
      .react-select__placeholder {
        color: #8c98a4;
        opacity: 1;
      }
      .react-select__input-container {
        padding: 0;
        margin: 0;
      }

      .react-select__multi-value {
        color: #1e2022;
        background-color: #e7eaf3;
        font-size: 0.875rem;
        padding: 0.28rem 0.16rem 0.28rem 0.875rem;
        border-radius: 4px;
        margin: 0 3px 3px 0;

        .react-select__multi-value__label {
          padding: 0;
        }

        .react-select__multi-value__remove {
          margin-left: 3px;
          &:hover {
            background-color: rgba(237, 76, 120, 0.1);
            color: #ed4c78;
          }
        }
      }
    }
  }
}

.form-node-title {
  background: rgba(189, 197, 209, 0.2);
  padding: 4px;
  margin-left: -32px;
  margin-right: -32px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-left: 32px !important;
    margin-right: 32px !important;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.ml-negative-1 {
  margin-left: -1rem;
}

.datatable-custom{
  .rdt_Pagination{
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    background-color: var(--bs-card-cap-bg);
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
    padding-top: 16px; 
    padding-bottom: 16px;
  }
}
.datatable-vendor {
  .rdt_Table {
    .rdt_TableHeadRow {
      border: 0;;
      .rdt_TableCol {
        border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
        border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
        // padding-right: 1.3125rem;
        // padding-left: 1.3125rem;
        color: #677788;
        font-size: 0.74375rem;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #f8fafd;
        vertical-align: middle;
        padding: 0.75rem 0.75rem;
        padding-right: 1.3125rem;
        padding-left: 1.3125rem;
      }
    }
    .rdt_TableBody{
      color: #677788;
      .rdt_TableRow{
        color: #677788;
        border: none;
        .rdt_TableCell{
          // padding: 0.75rem 0.75rem;
          padding-right: 1.3125rem;
          padding-left: 1.3125rem;
          padding-top: 16px; 
          padding-bottom: 16px;
          
        }
      }
    }
  }
  .__rdt_custom_sort_icon__::after {
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    width: 0.7rem;
    height: 0.7rem;
    background-image: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 0.7rem 0.7rem;
    content: "";
    margin-left: 0.5rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .fsBPnz:hover, .fsBPnz:focus{
    opacity: 1;
  }
  .dEUlpH {
    .asc.__rdt_custom_sort_icon__::after {
      background-image: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%230A595F'/%3E%3C/svg%3E%0A");
      content: "";
    }
    .desc.__rdt_custom_sort_icon__::after {
      background-image: url("data:image/svg+xml,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.3808C139.5 7.12345 152.5 7.12346 160.035 15.3808L262.976 128.193C274.106 140.39 265.453 160 248.941 160H43.0589C26.5474 160 17.8943 140.39 29.0238 128.193L131.965 15.3808Z' fill='%230A595F'/%3E%3Cpath d='M160.035 359.619C152.5 367.877 139.5 367.877 131.965 359.619L29.0238 246.807C17.8942 234.61 26.5473 215 43.0589 215L248.941 215C265.453 215 274.106 234.61 262.976 246.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E%0A");
      content: "";
    }
  }
}



.form-select-custom__control {
  min-height: calc(1.5em + 1.625rem) !important;
  border: .0625rem solid rgba(231,234,243,.7) !important;
  border-radius: 4px !important;
  // padding: 0.5rem 1rem;
  color: #3d4042;
  &.form-select-custom__control--is-focused {
      box-shadow: 0 0 1rem 0 rgb(140 152 164 / 25%) !important;
  }
  .form-select-custom__multi-value {
      background: #f3f4f8 !important;
      border-radius: 8px !important;

      .form-select-custom__multi-value__label {
          color: rgb(51, 51, 51) !important;
          // font-weight: 600 !important;
          font-size: 12px !important;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 8px;
      }
      .form-select-custom__multi-value__remove {
          color: #aec2d3;
          padding-right: 8px;
          padding-left: 8px;
          border-radius: 8px;
          &:hover {
              background-color: #f3f4f8;
              color: rgb(51, 51, 51);
          }
      }
  }
}
.form-select-custom__menu {
  width: auto;
  .form-select-custom__option {
      color: #323e45 !important;
      cursor: pointer;
      &.form-select-custom__option--is-focused {
          color: rgb(51, 51, 51) !important;
          // font-weight: 600;
          background: #f3f4f8 !important;
          background-color: rgba(189, 197, 209, 0.2) !important;
      }
      &.form-select-custom__option--is-selected {
          background: #f3f4f8 !important;
          color: rgb(51, 51, 51) !important;
          // font-weight: 600;
      }
  }
}

.form-select-custom.is-invalid {
  .form-select-custom__control {
      border-color: #d82525 !important;
      &:focus,
      &.form-select-custom__control--is-focused {
          box-shadow: 0 0 1rem 0 rgb(216 37 37 / 25%) !important;
      }
  }
}

.form-select-custom__placeholder {
  color: #8c98a4 !important;
  opacity: 1 !important;
}

.form-select-custom__option--is-selected {
  background-color: #7991c6 !important;
}

.form-select-custom-flags {
  .form-select-custom__control {
      width: max-content;
  }
  .form-select-custom__menu {
      width: auto;
  }
}


.nav-vertical.nav-pills .nav-link.active{
  background-color: rgb(10 89 95 / 20%);
  // color: #0a595f;
  // font-weight: 600;
  &:hover{
    color: #0a595f;
  }
}

.nav-link-disable{
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-header{
  padding-top: 28px;
  padding-bottom: 28px;
  border-bottom: 0.0625rem solid var(--bs-modal-footer-border-color);
}